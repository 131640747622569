import React, { useEffect } from 'react';
import { SearchTypeDetails } from 'types/SearchType';
import searchService from 'services/SearchService';
import { useHistory } from 'react-router-dom';
import { SEARCHES } from 'routes/Routes';
import SearchCreationFunnel from 'pages/common/search/SearchCreationFunnel';
import sessionManager from 'services/sessionManager';

export default function SearchCreation() {
  const history = useHistory();

  const submitSearch = (data: SearchTypeDetails) => {
    searchService.setSearch(data);
    history.push(SEARCHES);
  };

  useEffect(() => {
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'kyjnxdm8',
      name: sessionManager.getSession()?.user_name,
      email: sessionManager.getSession()?.user_mail,
      created_at: Date.now(),
      custom_launcher_selector: '#intercom_help_link',
      hide_default_launcher: true,
    });
  }, []);

  return (
    <div className="client-root">
      <div className="page-content">
        <div className="page-body search-creation-funnel">
          <SearchCreationFunnel onSubmit={submitSearch} submitting={false} />
        </div>
      </div>
    </div>
  );
}
