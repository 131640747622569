import React, { useState } from 'react';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { useHistory, useParams } from 'react-router-dom';
import {
  ID,
  VISIT_BOOKING_CONFIRM,
  VISIT_ID,
} from 'routes/Routes';
import { useVisitSlotBackend } from 'network/queries/VisitSlotQueries';
import { PossibleVisitSlot } from 'types/VisitSlot';
import BookingCalendar from 'pages/client-app/visit-booking/BookingCalendar';
import PropertyCard from 'pages/common/PropertyCard';
import BookingSelection from 'pages/client-app/visit-booking/BookingSelection';
import Messages from 'services/i18n/Messages';
import { NotificationService } from 'lib/notification';
import VisitBookingConfirm from 'pages/client-app/visit-booking/VisitBookingConfirm';
import { Routes } from 'routes/RoutesUtils';
import { visitStatusTypeEnum } from 'types/CandidatureBase';

type Param = {
  id: string,
  visitId?: string,
};

export default function VisitBooking() {
  const history = useHistory();
  const [selectedVisit, setSelectedVisit] = useState<PossibleVisitSlot | undefined>();
  const { id, visitId } = useParams<Param>();
  const candidatureQueries = useCandidatureBackend();
  const { getCandidature, getCandidaturePossibleVisitSlot } = candidatureQueries;
  const { data: candidature } = getCandidature(id);
  const { data: possibleSlot, isLoading } = getCandidaturePossibleVisitSlot(id);
  const [showMore, setShowMore] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const visitSlotQueries = useVisitSlotBackend();
  const { assignVisitSlot, updateVisitSlot } = visitSlotQueries;

  const selectSlot = (slot: PossibleVisitSlot) => {
    setSubmitting(true);
    if (visitId) {
      updateVisitSlot.mutateAsync({
        visitId,
        visitSlotId: slot.visitSlotID,
        assignVisit: {
          startDate: slot.startDate,
          endDate: slot.endDate,
          dateHash: slot.dateHash,
          candidatureID: id,
        },
      })
        .then((visit) => {
          history.push(Routes.withPath(VISIT_BOOKING_CONFIRM, [{ label: ID, value: id }, {
            label: VISIT_ID,
            value: visit.id,
          }]));
          NotificationService.notifySuccess(Messages.t('notifications.visit'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      assignVisitSlot.mutateAsync({
        visitSlotId: slot.visitSlotID,
        assignVisit: {
          startDate: slot.startDate,
          endDate: slot.endDate,
          dateHash: slot.dateHash,
          candidatureID: id,
        },
      })
        .then((visit) => {
          history.push(Routes.withPath(VISIT_BOOKING_CONFIRM, [{ label: ID, value: id }, {
            label: VISIT_ID,
            value: visit.id,
          }]));
          NotificationService.notifySuccess(Messages.t('notifications.visit'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };
  let bookingAvailable = true;
  if ((candidature?.visitStatus
      && candidature.visitStatus !== visitStatusTypeEnum.PROPOSED
      && candidature.visitStatus !== visitStatusTypeEnum.BOOKED)
    || (!isLoading && (possibleSlot?.length || 0) === 0)) {
    bookingAvailable = false;
  }
  if (candidature?.visitStatus === visitStatusTypeEnum.BOOKED && !visitId) {
    history.push('/visits/', { showModalVisitAlreadyBooked: true });
  }
  return (
    <div className="client-root">
      <div className="page-content visit-booking-page">
        <div className="application-title">
          SETTLESWEET
        </div>
        <div className="visit-booking-page-body">
          <div className="booking-calendar-container">
            {
              bookingAvailable ? (
                <>
                  {
                    possibleSlot && showMore && !selectedVisit && (
                      <BookingCalendar
                        submitting={submitting}
                        getIsDisable={(slot) => submitting || slot.remainingVisits < 1}
                        events={possibleSlot.filter((slot) => slot.remainingVisits > 0)}
                        onClick={(slot) => setSelectedVisit(slot)}
                      />
                    )
                  }
                  {
                    possibleSlot && !showMore && !selectedVisit && (
                      <BookingSelection
                        submitting={submitting}
                        selectVisit={(slot) => selectSlot(slot)}
                        visits={possibleSlot.filter((slot) => slot.remainingVisits > 0)}
                        showMore={() => setShowMore(true)}
                      />
                    )
                  }
                  {
                    selectedVisit && (
                      <VisitBookingConfirm
                        submitting={submitting}
                        visit={selectedVisit}
                        selectVisit={(slot) => selectSlot(slot)}
                        showMore={() => setSelectedVisit(undefined)}
                      />
                    )
                  }
                </>
              ) : (
                <div>
                  <h3>
                    {Messages.t('booking.visit.noAvailable')}
                  </h3>
                  <div>
                    {Messages.t('booking.visit.more')}
                  </div>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.settlesweet.com/marketplace"
                    className="link-as-button secondary marketplace-link"
                  >
                    {Messages.t('booking.visit.getMore')}
                  </a>
                </div>
              )
            }
          </div>
          {
            candidature && candidature.property && (
              <div className="property-card-container">
                <PropertyCard property={candidature.property} />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
