import React from 'react';
import house from 'assets/images/house.svg';
import Messages from 'services/i18n/Messages';
import { Link } from 'react-router-dom';
import { SEARCH_CREATE } from 'routes/Routes';

export default function SearchCreationLanding() {
  return (
    <div className="client-root">
      <div className="page-content">
        <div className="page-body search-creation-funnel search-landing">
          <div className="house-icon-container">
            <div className="house-icon-background" />
            <img src={house} alt="house" />
          </div>
          <h3>{Messages.t('search.landing')}</h3>
          <Link to={SEARCH_CREATE} className="link-as-button secondary">{Messages.t('search.continue')}</Link>
        </div>
      </div>
    </div>
  );
}
