import React, { useState } from 'react';
import {
  Page,
  Document,
} from 'react-pdf';
import Image from 'theme/Image';
import { IconButton } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import FileDisplay from 'pages/common/FileDisplay';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  src: string,
  isPdf?: boolean;
  displayAction?: boolean;
  onDelete?: () => void
  disabled?: boolean
};

export default function FilePreview(
  {
    src,
    isPdf,
    displayAction,
    onDelete,
    disabled,
  }: Props,
) {
  const [showPreview, setShowPreview] = useState(false);
  return (
    <div
      role="presentation"
      className="file-preview"
    >
      {
        isPdf || src.endsWith('.pdf') ? (
          <Document
            file={src}
          >
            <Page pageNumber={1} />
          </Document>
        ) : (
          <Image alt="document" src={src} />
        )
      }
      {
        displayAction && (
          <div className="preview-actions-container">
            <div
              role="presentation"
              className="preview-actions"
            >
              {
                onDelete && (
                  <IconButton disabled={disabled} onClick={onDelete}>
                    <Delete />
                  </IconButton>
                )
              }
              <IconButton onClick={() => setShowPreview(true)}>
                <Visibility />
              </IconButton>
            </div>
          </div>
        )
      }
      {
        showPreview && (
          <DialogWrapper
            onPointerUp={(e) => {
              e.stopPropagation();
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
            className="dialog-with-close"
            open
            darkCloseBtn
            onClose={() => setShowPreview(false)}
          >
            <FileDisplay src={src} />
          </DialogWrapper>
        )
      }
    </div>
  );
}
