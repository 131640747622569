import React from 'react';
import Messages from 'services/i18n/Messages';
import NavItem from 'pages/client-app/layout/NavItem';
import {
  CANDIDATURES,
  APPLICATION,
  PARAMETERS,
  VISITS, SEARCHES, PROPERTIES,
} from 'routes/Routes';
import {
  AccountCircleOutlined,
  Folder, House,
  Today, TrendingUp,
} from '@material-ui/icons';

type Props = {
  alternativeAction?: () => void
};

function NavBar({ alternativeAction }: Props) {
  return (
    <nav className="nav-bar">
      <div className="nav-content">
        <div className="nab-bar-header application-title">
          SETTLESWEET
        </div>
        <NavItem
          label={Messages.t('navbar.research')}
          icon={<House />}
          path={[SEARCHES, PROPERTIES]}
        />
        <NavItem
          label={Messages.t('navbar.candidatures')}
          icon={<TrendingUp />}
          path={CANDIDATURES}
          alternativeAction={alternativeAction}
        />
        <NavItem
          label={Messages.t('navbar.application')}
          icon={<Folder />}
          path={APPLICATION}
          alternativeAction={alternativeAction}
        />
        <NavItem
          label={Messages.t('navbar.visits')}
          icon={<Today />}
          path={VISITS}
          alternativeAction={alternativeAction}
        />
        <NavItem
          className="parameters-nav-item"
          label={Messages.t('navbar.parameters')}
          icon={<AccountCircleOutlined />}
          path={PARAMETERS}
        />
      </div>
    </nav>
  );
}

export default NavBar;
