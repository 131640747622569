import React, { useState, useEffect } from 'react';
import {
  DocumentCreation,
  DocumentData, DocumentTag,
  documentTypeEnum, optionalTags,
} from 'types/DocumentData';
import { useDropzone } from 'react-dropzone';
import DocumentUtils from 'services/DocumentUtils';
import { useDocumentBackend } from 'network/queries/DocumentQueries';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import {
  CloudUpload,
  Delete,
  Download, DragIndicator,
  Visibility,
} from '@material-ui/icons';
import {
  Checkbox, CircularProgress,
  DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import confirmationService from 'services/ConfirmationService';
import FileDisplay from 'pages/common/FileDisplay';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Button from 'theme/Button';
import { ReactSortable } from 'react-sortablejs';
import SpinButton from 'theme/SpinButton';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  documentsByTags: { [key: string]: DocumentData[] | undefined },
  tag: DocumentTag,
  documentGroupId: string,
  hideUpload?: boolean,
  onClose: () => void,
};

export default function DocumentTagViewer(
  {
    documentsByTags,
    tag,
    documentGroupId,
    hideUpload,
    onClose,
  }: Props,
) {
  const commentList = documentsByTags[tag]?.filter((doc) => doc.type === documentTypeEnum.COMMENT);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ comment: string }>({ defaultValues: { comment: commentList?.[0]?.comment } });

  const [showDocumentDetails, setShowDocumentDetails] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const documentQueries = useDocumentBackend();
  const {
    createDocuments,
    deleteDocument,
    updateDocument,
    updateDocumentList,
  } = documentQueries;
  const [filePreview, setFilePreview] = useState<string | undefined>();
  const documentList = documentsByTags[tag]?.filter((doc) => doc.type
    === documentTypeEnum.DOCUMENT);
  const [sortableDocumentList, setSortableDocumentList] = useState(documentList);

  useEffect(() => {
    setSortableDocumentList(documentList);
  }, [documentList?.length]);

  const updateDocuments = () => {
    if (sortableDocumentList) {
      updateDocumentList.mutateAsync({ documentGroupId, data: sortableDocumentList });
    }
  };

  const deleteDoc = async (documentId: string, isComment?: boolean) => {
    if (!isComment) {
      const isConfirmed = await confirmationService.confirm({
        title: Messages.t('confirmation.documentDelete.title'),
        message: Messages.t('confirmation.documentDelete.message'),
      });
      if (!isConfirmed) {
        return;
      }
    }
    setSubmitting(true);
    deleteDocument.mutateAsync({
      documentId,
      documentGroupId,
    }).then(() => NotificationService.notifySuccess(Messages.t('notifications.deleteDocument')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const submitDocuments = (toUploadDocs: DocumentCreation[], hideToast?: boolean) => {
    createDocuments.mutateAsync({
      data: toUploadDocs,
      documentGroupId,
    }).then(() => {
      if (!hideToast) {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      }
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 0,
    onDrop: async (dropedFiles: File[]) => {
      setSubmitting(true);
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      submitDocuments(filesResult.map((file) => ({
        tags: [tag],
        file,
        type: documentTypeEnum.DOCUMENT,
      })));
    },
  });

  const updateComment = (data: { comment: string }) => {
    setSubmitting(true);
    updateDocument.mutateAsync({
      documentId: commentList?.[0]?.id || '',
      documentGroupId,
      data: {
        comment: data.comment,
      },
    }).then(() => {
      onClose();
    }).finally(() => setSubmitting(false));
  };

  return (
    <div className="selector-and-viewer document-tag-viewer">
      <DialogWrapper
        open={showDocumentDetails}
        onClose={() => setShowDocumentDetails(false)}
      >
        <DialogTitle>
          {Messages.t('documents.awaited')}
        </DialogTitle>
        <DialogContent>
          {Messages.t(`document.tag.${tag}.details`)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDocumentDetails(false)}>
            {Messages.t('formButton.ok')}
          </Button>
        </DialogActions>
      </DialogWrapper>
      <h5>{Messages.t(`document.tag.${tag}`)}</h5>
      <button type="button" className="awaited-documents" onClick={() => setShowDocumentDetails(true)}>
        {Messages.t('documents.seeAwaited')}
      </button>
      {
        !hideUpload && (
          <div {...getRootProps({ className: `dropzone ${isDragActive ? 'dragover' : ''}` })}>
            <input {...getInputProps()} />
            <div className="input-placeholder">
              {
                submitting ? (
                  <CircularProgress />
                ) : (
                  <>
                    <CloudUpload />
                    {Messages.t('dragnDrop.dropHere')}
                  </>
                )
              }
            </div>
          </div>
        )
      }
      <div className="document-list">
        {
          sortableDocumentList && (
            <ReactSortable
              list={sortableDocumentList}
              animation={200}
              setList={(array) => setSortableDocumentList(array.map((document, index) => ({
                ...document,
                order: array.length - index,
              })))}
              handle=".handle-drag"
              onEnd={updateDocuments}
            >
              {
                sortableDocumentList
                  .sort((a, b) => ((((b.order !== null && b.order !== undefined) ? b.order : -1))
                      - ((a.order !== null && a.order !== undefined) ? a.order : -1))
                    || (a.createdAt || '').localeCompare(b.createdAt || ''))
                  .map((doc) => (
                    <div key={doc.id} className="document-data-container">
                      <div className="handle-drag">
                        <DragIndicator />
                      </div>
                      <div className="document-data">
                        {decodeURI(doc.link?.split('/').slice(-1)[0] || '')}
                        <div className="document-actions">
                          <IconButton onClick={() => setFilePreview(doc.link)}>
                            <Visibility />
                          </IconButton>
                          <a href={doc.link} download>
                            <IconButton>
                              <Download />
                            </IconButton>
                          </a>
                          <IconButton onClick={() => deleteDoc(doc.id)}>
                            <Delete />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))
              }
            </ReactSortable>
          )
        }
        {
          !optionalTags.includes(tag) && (documentList?.length || 0) === 0 && (
            <div>
              <FormControlLabel
                onClick={
                  (commentList?.length || 0) === 0
                    ? (() => submitDocuments([{
                      tags: [tag],
                      comment: '',
                      type: documentTypeEnum.COMMENT,
                    }], true))
                    : () => deleteDoc(commentList?.[0].id || '', true)
                }
                disabled={submitting}
                control={<Checkbox checked={(commentList?.length || 0) > 0} />}
                label={Messages.t('comment.add')}
              />
              {
                commentList && commentList.length > 0 && (
                  <form onSubmit={handleSubmit(updateComment)}>
                    <Controller
                      name="comment"
                      control={control}
                      rules={{ required: true }}
                      render={(controller) => (
                        <TextFieldWrapper
                          apiErrors={{}}
                          error={errors}
                          type="textarea"
                          rows={5}
                          control={controller}
                          label={Messages.t('field.noDoc')}
                        />
                      )}
                    />
                    <SpinButton
                      editing
                      spin={submitting}
                      title={Messages.t('formButton.validate')}
                    />
                  </form>
                )
              }
            </div>
          )
        }
        {
          filePreview && (
            <DialogWrapper open onClose={() => setFilePreview(undefined)}>
              <FileDisplay src={filePreview} />
            </DialogWrapper>
          )
        }
      </div>
    </div>
  );
}
