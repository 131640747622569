import React from 'react';
// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';
import LogInForm from 'pages/public-app/authentification/LogInForm';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import {
  DASHBOARD,
  LOG_IN, PARAMETERS, PROPERTIES_DETAILS,
  SEARCH_CREATE, SEARCH_CREATE_LANDING, SEARCH_PROPERTY, SEARCHES,
  SIGN_IN,
} from 'routes/Routes';
import SignInForm from 'pages/public-app/authentification/SignInForm';
import ClassicLanding from 'pages/public-app/landing/ClassicLanding';
import SearchCreation from 'pages/public-app/search/SearchCreation';
import { useObservable } from 'micro-observables';
import signInService from 'services/SignInService';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';

import PublicSearchRouter from 'pages/public-app/PublicSearchRouter';
import PublicSearchRedirect from 'pages/public-app/PublicSearchRedirect';
import SearchCreationLanding from 'pages/common/search/SearchCreationLanding';
import DialogWrapper from 'pages/common/DialogWrapper';

export default function PublicRoot() {
  const history = useHistory();
  const showSignInModal: boolean = useObservable(signInService.getShowSignInModal());

  return (
    <>
      <DialogWrapper open={showSignInModal} onClose={() => signInService.setShowSignInModal(false, '')}>
        <DialogTitle />
        <DialogContent>
          {Messages.t('signin.modalMessage')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              signInService.setShowSignInModal(false, undefined);
              history.push(SIGN_IN);
            }}
          >
            {Messages.t('login.signIn')}
          </Button>
        </DialogActions>
      </DialogWrapper>
      <Switch>
        <Route exact path={SIGN_IN} component={SignInForm} />
        <Route exact path={LOG_IN} component={LogInForm} />
        <Route exact path={DASHBOARD} component={ClassicLanding} />
        <Route exact path={SEARCH_CREATE_LANDING} component={SearchCreationLanding} />
        <Route exact path={SEARCH_CREATE} component={SearchCreation} />
        <Route exact path={SEARCH_PROPERTY} component={PublicSearchRedirect} />
        <Route path={[SEARCHES, PROPERTIES_DETAILS, PARAMETERS]} component={PublicSearchRouter} />
        <Route render={() => <Redirect to={DASHBOARD} />} />
      </Switch>
    </>
  );
}
