const env = process.env || {};

const settings = {
  transition: env.REACT_APP_TRANSITIONS === 'YES' ? undefined : false,
  publicUrl: env.PUBLIC_URL || '',
  backendEndpoint: process.env.REACT_APP_HUNTER_BACKEND,
  disableWorker: process.env.REACT_APP_DISABLE_WORKER === 'NO',
  env: process.env.REACT_APP_ENV,
  fakeIsochrone: process.env.REACT_APP_FAKE_ISOCHRONE === 'YES',
  refresh_candidate_on_page_load:
    process.env.REACT_APP_REFRESH_CANDIDATE_ON_PAGE_LOAD === 'YES',
  geojsonGridCellSide: 150,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  baseUrl: process.env.REACT_APP_BASE_URL,

};

export default settings;
