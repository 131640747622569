import React from 'react';
import { VisitDetailed } from 'types/Visit';
import { PersonOutline, Today } from '@material-ui/icons';
import { format } from 'date-fns';
import localService from 'services/i18n/LocalService';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, VISITS_DETAILS } from 'routes/Routes';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  visitList: VisitDetailed[]
  chronologicalSort?: boolean
};

export default function VisitList({ visitList, chronologicalSort }: Props) {
  return (
    <>
      {
        visitList.sort((a, b) => (chronologicalSort
          ? a.startDate.localeCompare(b.startDate)
          : b.startDate.localeCompare(a.startDate)))
          .map((visit) => (
            <div key={visit.id}>
              <Link
                to={Routes.withPath(VISITS_DETAILS,
                  [{ label: ID, value: visit.id }])}
                className={`visit-item ${visit.isCancel ? 'cancel' : ''}`}
              >
                <div className="visit-item-content">
                  <div className="visit-item-header">
                    <Today />
                    <div>
                      {format(new Date(visit.startDate), 'ccc dd LLLL à HH:mm', { locale: localService.getDateLocal() })}
                    </div>
                  </div>
                  <div className="visit-item-infos">
                    <div className="property-list-item">
                      <div className="property-image">
                        <Image
                          src={PropertyUtils.getPropertyMainImage(visit.property)}
                          alt="property"
                        />
                      </div>
                      <div className="property-info">
                        <div className="property-price">
                          {visit.property.price} &euro;
                          <span className="price-month">{Messages.t('price.byMonth')}</span>
                        </div>
                        <div className="property-address">
                          <LocationMarkerIcon />
                          <span>{visit.address}</span>
                        </div>
                      </div>
                    </div>
                    <div className="agent-infos-container">
                      {
                        visit.isCancel ? (
                          <div className="agent-infos">
                            {Messages.t('visit.canceled')}
                          </div>
                        ) : (
                          <div className="agent-infos">
                            <PersonOutline />
                            <div className="agent-infos-body">
                              <div className="agent-infos-header">{Messages.t('visit.responsible')}</div>
                              <div>
                                {`${visit.agent.firstname || ''} ${visit.agent.lastname || ''}`}
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
      }
    </>
  );
}
