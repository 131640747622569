import { Traduction } from 'services/i18n/Traduction';

const fr = {
  'field.error.missingCurrentContact': 'Vous devez au moins sélectionner un contact avec votre email',
  'search.property.furnished': 'Meublé',
  'search.property.unFurnished': 'Non meublé',
  'property.count.none': 'Moins d\'une annonce par jours',
  'property.count.less': 'Moins de 5 annonces par jours',
  'property.count.average': 'Environ %{count} annonces par jours',
  'property.count.more': 'Plus de %{count} annonces par jours',
  'search.question.area': 'Une idée de la surface désirée ?',
  'search.question.budget': 'Quel est votre budget ?',
  'search.question.rooms': 'De combien de pièces avez-vous besoin ?',
  'search.landing': 'Bienvenue sur Settlesweet, votre recherche de logement démarre ici !',
  'search.continue': 'CONTINUER',
  'search.mySearch': 'Ma recherche',
  'search.question.propertyType': 'Quel type de bien recherchez-vous ?',
  'search.question.postalCodes': 'Ou souhaitez-vous vous installer ?',
  'search.question.showResult': 'Voir les résultats',
  'search.question.furnished': 'Et enfin, vous êtes plutôt meublé ou non meublé ?',
  'signin.modalMessage': 'Merci de vous inscrire pour pouvoir utiliser toutes les fonctionnalités de Settlesweet (passer des biens en favoris, postuler directement depuis l’application, préparer son dossier locataire...)',
  'landing.startSearch': 'Démarrer ma recherche',
  'landing.login': 'Se connecter / s\'inscrire',
  'parameters.updatePassword': 'Modifier mon mot de passe',
  'candidature.modal.title': 'Questions sur le bien',
  'candidate.creation.choose': 'Choix de la candidature',
  'candidate.choose.save': 'Toutes vos réponses seront sauvegardées pour les prochaines candidatures, mais vous pourrez toujours les changer.',
  'candidate.choose.recap': 'Nous utilisons les informations de vos dossiers pour envoyer un mail récapitulatif à la personne en charge du bien.',
  'candidate.choose.recap.swtPro': 'Pour postuler en un clic à cette annonce, veuillez sélectionner ci-dessous le ou les dossiers à intégrer à votre candidature.<br/>Vous pouvez aussi créer et modifier ces dossiers à tout moment depuis l\'onglet Dossier',
  'landing.message': 'Bienvenue sur Settlesweet, trouvez facilement votre prochain logement grâce à notre application.',
  'formButton.clickHere': 'Cliquez ici',
  'search.noAlerts': 'Vous n\'avez aucune alerte de créée.',
  'search.noShortlist': 'Vous n\'avez pas de biens en Favoris. Faites un tour dans les résultats de recherche pour en sélectionner',
  'search.toCreateOne': 'pour en mettre une en place',
  'search.noResult': 'Il n\'y a actuellement aucun bien qui correspond à vos critères.',
  'search.create': 'Nouvelle alerte',
  'search.sort.price': 'Prix décroissant',
  'search.sort.area': 'Surface décroissante',
  'search.sort.published_at': 'Date de publication décroissante',
  'search.sort.price_asc': 'Prix croissant',
  'search.sort.area_asc': 'Surface croissante',
  'search.sort.published_at_asc': 'Date de publication croissante',
  'search.sort.asc': 'Ascendant',
  'search.sort.desc': 'Descendant',
  'search.sort.results': 'Trier les résultats',
  'search.quit.action': 'Quitter la création de l\'alerte',
  'search.quit.cancel': 'Reprendre la creation',
  'clipBoardButton.linkCopied': 'Lien copié',
  'search.quit.message': 'Vous allez quitter la création de l’alerte de recherche.<br/>Etes-vous sûr de vouloir le faire ?',
  'pageSelector.search': 'Tous les biens',
  'pageSelector.shortlist': 'Favoris',
  'property.seen': 'Vu',
  'property.rooms.more': 'Plus de 5',
  'property.showSource': 'Voir l\'annonce originale',
  'property.rooms': '%{smart_count} pièce |||| %{smart_count} pièces',
  'property.managedBy': 'Géré par l\'agence %{agencyName}',
  'property.bedroom': '%{smart_count} chambre |||| %{smart_count} chambres',
  'property.area': '%{smart_count} m2',
  'property.furnished': 'meublé',
  'property.askViewing': 'Candidater',
  'property.alreadyContacted': 'Déjà contacté',
  'property.noMailFound': 'Nous ne trouvons pas d\'adresses email liée à cette annonce pour la contacter. Voulez-vous ouvrir l\'annonce afin de la trouver ?',
  'property.openUrl': 'Ouvrir l\'annonce',
  'property.candidateCreated': 'Pour augmenter vos chances, nous vous recommandons aussi d’appeler l’agence ou le propriétaire pour bien marquer votre intérêt.<br/><br/>Une candidature pour ce bien vient d’être créée dans la partie "Suivi".<br/>Vous pourrez facilement suivre l’évolution de votre dossier de cette façon.',
  'property.location': 'Localisation',
  'property.showCandidature': 'Voir la candidature',
  'agency.unknown': 'Agence inconnue',
  'property.particular': 'Particulier',
  'property.elevator': 'ascenseur',
  'property.floor': '%{smart_count}e étage',
  'property.isSwtPro': 'Candidature direct',
  'property.visitAsked': 'Contacté',
  'field.garantDatas': 'Données du Garant',
  'field.type': 'Type',
  'field.informations': 'Informations',
  'field.documents': 'Documents',
  'field.furnished': 'Meublé',
  'field.bedroom': 'Chambres',
  'field.candidatures': 'Candidatures',
  'field.profession': 'Profession',
  'field.gliEgible': 'Eligibilité à la GLI',
  'field.personalSituation': 'Situation personnelle',
  'field.professionalSituation': 'Situation professionnelle',
  'field.trialPeriod': 'Période d\'essai validée',
  'field.professionalDatas': 'Données Professionnelles',
  'field.phoneNumber': 'Téléphone',
  'field.firstName': 'Prénom',
  'field.name': 'Nom',
  'field.searchName': 'Nom de la recherche',
  'field.amount': 'Montant maximum garanti',
  'field.lastName': 'Nom',
  'field.leaseType': 'Type de contrat',
  'field.email': 'Email',
  'field.enterprise': 'Entreprise',
  'form.error.name': 'Veuillez renseigner au moins un nom ou un prénom',
  'field.personalDatas': 'Données personnelles',
  'field.monthlyIncome': 'Revenus mensuels net',
  'property.select': 'Sélectionner',
  'booking.visit.getMore': 'Obtenir d\'autres visites',
  'booking.visit.more': 'Mais Settlesweet peut vous aider à obtenir d\'autres visites',
  'booking.visit.noAvailable': 'Tous les créneaux de visite ont déjà été pourvu :(',
  'visit.cancel.confirm': 'Confirmer l\'annulation',
  'visit.cancel.message': 'Souhaitez-vous annuler le rendez-vous du %{date} ?',
  'visit.calendar.agent': 'Agent sur place: ',
  'visit.calendar.note': 'Notes: ',
  'visit.calendar.cancel': 'Annuler la visite',
  'visit.calendar.agency': 'Agence: ',
  'visit.calendar.appartement': 'Appartement %{nRooms}p, %{area}m2, %{price}€',
  'visit.calendar.title': 'Visite %{nRooms}p, %{area}m2, %{price}€ (%{agencyName}) au %{address}',
  'visit.canceled': 'La visite a été annulée',
  'confirmation.cancelVisit.title': 'Annuler la visite',
  'confirmation.cancelVisit.message': 'Vous êtes sur le point d\'annuler cette visite',
  'visit.addToCalendar': 'Ajouter à votre calendrier',
  'visit.confirmed': 'Visite confirmée !',
  'visit.alreadyBooked': 'Vous avez déjà programmé une visite pour ce bien !',
  'visit.seeUpcomingVisits': 'Voir mes visites à venir',
  'visit.move': 'Déplacer le RDV',
  'visit.cancel': 'Annuler le RDV',
  'error.expiredToken': 'Votre lien de connexion est expiré, veuillez indiquer votre email ci-dessous pour en recevoir un nouveau.',
  'visit.with': 'Avec %{name}',
  'visit.personalSpace': 'Accédez à votre espace',
  'booking.visit.dateFormatFull': 'EEEE dd MMMM \'à\' HH:mm',
  'booking.visit.dateFormat': 'EEEE dd MMM \'à\' HH:mm',
  'booking.visit.showMore': 'Afficher plus de créneaux',
  'booking.visit.change': 'Changer de créneau',
  'booking.visit.nextAvailable': 'Prochaine visite le %{date}',
  'booking.visit': 'Choisissez l\'horaire de la visite',
  'case.automaticShared': 'L\'ajout et la modification de documents sont automatiquement transmis à l\'agent',
  'document.congrat': 'Félicitations !',
  'document.replace': 'Supprimer les %{pageNumber} pages restantes',
  'document.update': 'Modifier les documents',
  'document.drop': 'Déposer chaque page dans la catégorie correspondante (Page %{number} sur %{total})',
  'document.finished': 'J\'ai terminé',
  'field.passwordConfirm': 'Confirmez votre mot de passe',
  'form.error.password': 'Votre mot de passe doit respecter les règles suivantes : <br/>-Au moins 8 caractères<br/>-Au moins une majuscule<br/>-Au moins une minuscule<br/>-Au moins un chiffre<br/>-Pas de caractères spéciaux',
  'document.quit': 'Cliquez sur le bouton "J\'ai terminé" en haut si vous avez catégorisé tous les documents ou ajoutez un ou plusieurs document(s) à catégoriser',
  'field.notMatchingPassword': 'Les mots de passe ne correspondent pas',
  'field.password': 'Mot de passe',
  'error.invalidMail': 'Mail invalide',
  'notifications.update': 'Vos modifications ont bien été prises en compte',
  'notifications.candidature': 'Votre candidature a bien été transmise',
  'notifications.error': 'Une erreur s\'est produite',
  'notifications.visit': 'Visite confirmée',
  'comment.add': 'Je n\'ai pas ce document',
  'form.error.GarantDuplicateName': 'Un garant avec le même nom existe déjà.',
  'form.error.GarantDuplicate': 'Un garant avec le même nom existe déjà. Merci de préciser le nom du garant avec le nom du locataire concerné. Par exemple : Garantme John Doe',
  'notifications.delete': 'Donnée supprimée',
  'notifications.deleteDocument': 'Document supprimé',
  'notifications.mailSend': 'Un mail de connexion vous a été envoyé',
  'search.delete': 'Voulez-vous supprimer cette recherche ?',
  'generics.all': 'N/A',
  'generics.or': 'OU',
  'formButton.back': 'Retour',
  'generics.YES': 'Oui',
  'generics.ALL': 'Peu importe',
  'generics.NO': 'Non',
  'pageSelector.futurVisit': 'Visite à venir',
  'pageSelector.pastVisit': 'Visite passée',
  'formButton.sendConnectionMail': 'Recevoir le mail de connexion',
  'formButton.passwordConnection': 'Se connecter par mot de passe',
  'error.passwordInvalid': 'La combinaison mot de passe / email est invalide',
  'error.mailInvalide': 'Un compte avec ce mail existe déja',
  'dates.publishedDate.unknown': 'unknown',
  'dates.publishedDate.now': 'A l\'instant',
  'dates.publishedDate.minutes': 'Il y a %{deltaInMinutes} min',
  'dates.publishedDate.hours': 'Il y a %{deltaInHours} h',
  'dates.publishedDate.days': 'Il y a %{deltaInDays} jours',
  'dates.publishedDate.months': 'Il y a %{deltaInMonths} mois',
  'dates.publishedDate.years': 'Il y a %{deltaInYears} ans',
  'formButton.confirm': 'Confirmer',
  'formButton.delete': 'Supprimer',
  'formButton.deleteSelection': 'Supprimer la séléction',
  'formButton.send': 'Envoyer',
  'formButton.ok': 'OK',
  'formButton.start': 'Commencer',
  'formButton.startVariant': 'Démarrer',
  'form.start.hello': 'Bonjour,',
  'form.start.organize': 'Afin d’organiser une visite du bien sélectionné ou obtenir davantage de précisions, nous vous remercions de compléter les renseignements demandés ci-après.',
  'form.start.justification': 'Aucune pièce justificative ne sera demandée, il s’agit simplement d’un premier contact avec nos services.',
  'form.start.team': 'Dès réception, nos équipes reviendront vers vous au plus tôt.',
  'form.start.subPart1': 'Notre prestataire Settlesweet s\'occupe du traitement des données. A la fin du questionnaire, en cliquant sur Envoyer, vous acceptez sans réserve les conditions de traitement des données. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits,',
  'form.start.clickHere': 'cliquez ici.',
  'formButton.validate': 'Valider',
  'document.deleteN': 'Voulez-vous supprimer les %{nbDocument} pages sélectionnées ?',
  'document.deleteN.tooltip': 'Supprimer %{nbDocument} documents',

  'field.comment': 'Commentaire',
  'field.noDoc': 'Pourquoi n\'avez-vous pas ce document ?',
  'field.message': 'Message',
  'case.share.congrat': 'Félicitations vous venez de partager votre dossier ! Nous espérons que votre candidature aboutira 🤞. Tout futur(s) ajout(s) de document(s) sera automatiquement transmis à l\'agence.<br/>'
    + '<br/>'
    + 'Pour accroitre vos chances d\'être sélectionné, vous pouvez ajouter un message personnalisé pour l\'agent immobilier.',
  'formButton.modify': 'Modifier',
  'field.passwordChange': 'Nouveau mot de passe',
  'formButton.add': 'Ajouter',
  'formButton.edit': 'Modifier',
  'formButton.submitting': 'Chargement',
  'placeType.airport': 'Airport',
  'placeType.amusement_park': 'Park',
  'placeType.aquarium': 'Aquarium',
  'placeType.art_gallery': 'Gallery',
  'placeType.bank': 'Bank',
  'placeType.bar': 'Bar',
  'placeType.bus_station': 'Bus station',
  'placeType.gym': 'Gym',
  'placeType.hospital': 'Hospital',
  'placeType.light_rail_station': 'Light rail',
  'placeType.museum': 'Museum',
  'placeType.shopping_mall': 'Mall',
  'placeType.restaurant': 'Restaurant',
  'placeType.stadium': 'Stadium',
  'placeType.train_station': 'Train station',
  'placeType.subway_station': 'Subway',
  'placeType.university': 'University',
  'form.action.manuallyEnterAddress': 'Saisir l\'adresse manuellement',
  'field.address': 'Adresse',
  'field.zipcode': 'Code postal',
  'field.street': 'Rue',
  'field.search': 'Recherche',
  'field.country': 'Pays',
  'field.city': 'Ville',
  'formButton.save': 'Confirmer',
  'form.error.requierd': 'Champ requis',
  'form.error.email': 'Email invalide',
  'form.error.default': 'Ce champ est invalide',
  'image.add': 'Ajouter',
  'image.addPhoto': 'Ajouter une image',
  'dragnDrop.dropHere': 'Cliquez ou deposez votre document ici',
  'documents.uploadHere': 'Uploadez ici votre dossier de location complet avec tous les documents',
  'tooltip.upload': 'Ajouter fichier',
  'documents.seeAwaited': 'Voir le(s) justificatif(s) attendu(s)',
  'documents.awaited': 'Documents attendus',
  'formButton.cancel': 'Annuler',
  'install.never': 'Ne plus demander',
  'generics.neverShow': 'Ne plus me le montrer',
  'formButton.install': 'installer',
  'install.app': 'Installer l\'application',
  'button.logout': 'Déconnexion',
  'button.login': 'Connexion',
  'button.signin': 'Inscription',
  'login.fillData': 'Renseignez vos informations pour profiter pleinement de Settlesweet',
  'login.noAccount': 'Pas encore de compte ?',
  'login.signIn': 'Je m\'inscris',
  'field.postalCodes': 'Villes, code postal',
  'field.rooms': 'Pièces',
  'field.bedroomsNumber': 'Nombre de chambres',
  'field.roomsNumber': 'Nombre de pièces',
  'field.propertyDetails': 'Détail du logement',
  'search.results': 'Résultats',
  'field.allZone': 'Tous les arrondissements',
  'field.budget': 'Budget',
  'field.propertyType': 'Type de bien',
  'property.type.ALL': 'Peu importe',
  'property.type.HOUSE': 'Maison',
  'property.type.APARTMENT': 'Appartement',
  'field.area': 'Surface',
  'field.notification': 'Notifications',
  'field.notificationSearch': 'Autoriser les notifications sur cette alerte',
  'field.priceMin': 'Prix minimum',
  'field.priceMax': 'Prix maximum',
  'field.areaMin': 'Surface minimum',
  'field.areaMax': 'Surface maximum',
  'navbar.research': 'Recherche',
  'navbar.candidatures': 'Suivi',
  'navbar.application': 'Dossiers',
  'navbar.visits': 'Visites',
  'navbar.parameters': 'Paramètres',
  'parameters.title': 'Mon compte',
  'parameters.help': 'Nous contacter',
  'price.byMonth': '/mois',
  'property.showDetails': 'Voir l\'annonce',
  'visit.responsible': 'Responsable de la visite',
  'visit.notes': 'Note',
  'contacts.title': 'Contacts',
  'contacts.create': 'Créer contact',
  'garants.create': 'Créer garant',
  'application.add': 'Ajouter un dossier',
  'application.type': 'Définir le type du dossier',
  'install.install': 'Installer l\'application sur votre Iphone : Taper',
  'install.andAdd': 'et ensuite "Ajouter à l\'écran d\'accueil"',
  'occupants.title': 'Locataire(s)',
  'garants.title': 'Garant(s)',
  'candidate.choose': 'A quelle(s) candidature(s) souhaitez-vous rattacher ce nouveau dossier ? ',
  'occupants.field': 'Locataire',
  'occupants.addOccupant': 'Ajouter locataire',
  'occupants.addGarant': 'Ajouter garant',
  'garants.field': 'Garant',
  'garants.notShared': 'Tous les documents de votre candidature ne sont pas transmis à l\'agent. Cliquez ci-dessous pour tout transmettre.',
  'case.title': 'Garants',
  'case.incomplete': 'Dossier incomplet',
  'case.incomplete.message': 'Attention, votre dossier ne semble pas être complet. En partageant un dossier incomplet à l\'agent, vos chances d\'être sélectionné sont très faibles. Les dossiers complets sont étudiés en priorité. Nous vous recommandons fortement de compléter votre dossier à 100% pour maximiser vos chances.',
  'case.incomplete.fill': 'Compléter mon dossier',
  'case.incomplete.send': 'Transmettre le dossier incomplet',
  'case.complete': 'Mon dossier est complet',
  'case.share': 'Transmettre les documents',
  'case.shareMine': 'Transmettre mes documents',
  'case.select': 'Sélectionnez un dossier',
  'case.addContact': 'Ajouter un locataire',
  'case.addContact.subtitle': 'Ajouter un %{contactType} existant ou créer un nouveau dossier',
  'case.addGarant': 'Ajouter un garant',
  'case.add': 'Rajouter',
  'case.added': 'Ajouté',
  'case.create': 'Creer un dossier',
  'case.sharedSuccessfully': 'Les documents ont bien été partagés avec l\'agence !',
  'case.documentsRequested': 'L\'agent vous demande des documents supplémentaires.<br/> Ajoutez ces documents à votre dossier et cliquez sur le bouton ci-dessous.',
  'document.type': 'Type de document',
  'document.select': 'Relier un dossier existant',
  'document.fill': 'Ajouter des documents',
  'document.typeSelect': 'Sélectionnez le type de document',
  'commentModal.title': 'Pourquoi n\'avez-vous pas ce document ?',
  'candidature.postulationDate': 'Postulé %{date}',
  'candidature.noProperty': 'Pas de propriété sélectionnnée',
  'document.createNewCase': 'Créer un nouveau dossier',
  'document.status.notShared': 'Documents non transmis',
  'document.status.asked': 'Documents en attente',
  'document.status.accepted': 'Dossier accepté',
  'document.status.refused': 'Dossier refusé',
  'document.status.sent': 'Dossier envoyé ✈️',
  'document.notShared.contact': 'Les documents de ce locataire ne sont pas transmis à l\'agent.',
  'document.notShared.garant': 'Les documents de ce garant ne sont pas transmis à l\'agent.',
  'document.status.missing': 'Demande d’informations',
  'contact.remove': 'Retirer de la candidature',
  'document.status.sentPartial': 'Documents partiellement transmis',
  'document.selector.modalTitle': 'Pour qui créer un nouveau dossier ?',
  'document.section.ID': 'Identité',
  'document.section.OTHER': 'Autres',
  'document.section.TAX_NOTICE': 'Avis d\'imposition',
  'document.section.SITUATION': 'Situation professionnelle',
  'document.section.HOME': 'Domicile',
  'document.section.RESSOURCES': 'Ressources',
  'document.section.GUARANTEE_CERTIFICATE': 'Certificat de garantie',
  'document.section.EMPLOYER_PROOF': 'Justificatif(s) de garantie employeur',
  'document.section.GUARANTEE_COMMITMENT': 'Engagement de cautionnement',
  'document.tag.OTHER': 'Autres',
  'document.tag.ID': 'Passeport ou carte d\'identité',
  'document.tag.PAYSLIP_N1': 'Fiche de paie n-1',
  'document.tag.PAYSLIP_N2': 'Fiche de paie n-2',
  'document.tag.PAYSLIP_N3': 'Fiche de paie n-3',
  'document.tag.EMPLOYEMENT_CONTRACT': 'Contrat de travail',
  'document.tag.PROOF_OF_ACTIVTY': 'Justificatif d\'activité professionnelle',
  'document.tag.SCHOOL_CERTIFICATE': 'Certificat de scolarité',
  'document.tag.NOTICE_OF_SITUATION': 'Avis de situation',
  'document.tag.TAX_NOTICE': 'Avis d\'imposition',
  'document.tag.ADDRESS_PROOF': 'Justificatif de domicile',
  'document.tag.ACCOUNTING': 'Bilans comptables',
  'document.tag.RIGHT_NOTICE': 'Attestation de droits',
  'document.tag.OTHER_RESSOURCES_PROOF': 'Autre(s) justificatif(s) de ressources (facultatif)',
  'document.tag.RETIREMENT_SLIPS': 'Bulletins de retraite',
  'document.tag.RESSOURCES_PROOF': 'Justificatif de ressources (facultatif)',
  'document.tag.GUARANTEE_CERTIFICATE': 'Certificat de garantie',
  'document.tag.EMPLOYER_PROOF': 'Justificatif(s) de garantie employeur',
  'document.tag.GUARANTEE_COMMITMENT': 'Engagement de cautionnement',
  'document.tag.OTHER.details': 'Autres documents',
  'document.tag.ID.details': 'Passeport (pages 2 et 3) ou carte nationale d\'identité (recto/verso).<br/>'
    + '<br/>'
    + 'Si vous êtes citoyen hors UE, veuillez joindre un visa ou titre de séjour (recto/verso) en cours de validité (ou expiré avec récipissé de renouvellement joint)',
  'document.tag.PAYSLIP_N1.details': 'Bulletin de salaire du dernier mois',
  'document.tag.PAYSLIP_N2.details': 'Bulletin de salaire de l\'avant-dernier mois',
  'document.tag.PAYSLIP_N3.details': 'Bulletin de salaire de l\'avant-avant-dernier mois',
  'document.tag.EMPLOYEMENT_CONTRACT.details': 'Votre contrat de travail soit être signé par vous et votre employeur.<br/>'
    + '<br/>'
    + 'Si vous n’avez pas de contrat de travail, vous pouvez joindre une attestation de votre employeur de moins de 3 mois avec date d\'entrée en fonction, rémunération et date de validation de la période d\'essai.<br/>'
    + '<br/>'
    + 'Si vous êtes fonctionnaire, vous pouvez joindre un arrêté de nomination.',
  'document.tag.PROOF_OF_ACTIVTY.details': 'Selon votre situation, vous pouvez joindre l\'un des justificatifs suivants :<br/>'
    + '- Extrait K bis du registre du commerce et des sociétés de moins de 3 mois (toute société commerciale)<br/>'
    + '- Certificat d\'identification de l\'Insee comportant les numéros d\'identification (travailleur indépendant)<br/>'
    + '- Extrait D1 original du registre des métiers de moins de 3 mois (artisan)<br/>'
    + '- Carte professionnelle (profession libérale)<br/>'
    + '- Toute pièce récente attestant de l\'activité professionnelle',
  'document.tag.SCHOOL_CERTIFICATE.details': 'Certificat de scolarité ou carte étudiante',
  'document.tag.NOTICE_OF_SITUATION.details': 'Avis de situation de moins de 3 mois (Pôle Emploi) ou attestation d\'ouverture des droits aux prestations sociales ',
  'document.tag.TAX_NOTICE.details': 'Toutes les pages du dernier avis d\'imposition disponible',
  'document.tag.ADDRESS_PROOF.details': 'Selon votre situation, vous pouvez transmettre un ou plusieurs des documents suivants :<br/>'
    + '- Quittances de loyer des 3 derniers mois (locataire)<br/>'
    + '- Dernier avis de taxes foncières ou titre de propriété (propriétaire)<br/>'
    + '- Attestation d\'hébergement sur l\'honneur de moins de 3 mois accompagnée de la pièce d\'identité de l\'hébergeant et d\'un justificatif de domicile à son nom (hébergé à titre gratuit)<br/>'
    + '<br/>'
    + 'A défaut, vous pouvez également fournir :<br/>'
    + '- Facture d\'eau, de gaz ou d\'électricité de moins de 3 mois <br/>'
    + '- Attestation d\'assurance logement de moins de 3 mois',
  'document.tag.ACCOUNTING.details': 'Bilans comptables des 2 dernières années ou attestation de déclaration de ressources délivrée par un comptable externe.',
  'document.tag.RIGHT_NOTICE.details': 'Attestations de versements de prestations sociales des 3 derniers mois (ARE, RSA...) ou justificatif de l\'ouverture des droits établis par l\'organisme payeur',
  'document.tag.OTHER_RESSOURCES_PROOF.details': 'E15<br/>'
    + '<br/>'
    + 'Si vous percevez des revenus complémentaires, vous pouvez transmettre un ou plusieurs des documents suivants :<br/>'
    + '- Justificatifs de revenus fonciers, de rentes viagères ou de revenus de valeurs et capitaux mobiliers<br/>'
    + '- Titre de propriété d’un bien immobilier ou dernier avis de taxe foncière<br/>'
    + '- Attestations de versements de prestations sociales et familiales et allocations perçues lors des 3 derniers mois ou justificatif de l\'ouverture des droits établis par l\'organisme payeur<br/>'
    + '- Extrait de l’ordonnance du tribunal mentionnant le montant de la pension alimentaire<br/>'
    + '- Tout autre document attestant de la perception d\'un revenu',
  'document.tag.RETIREMENT_SLIPS.details': 'Justificatif de versement des indemnités de retraite des 3 derniers mois (décompte de paiement ou bulletins de pension)',
  'document.tag.RESSOURCES_PROOF.details': '- Avis d\'attribution ou attestation de versement d\'une bourse <br/>'
    + '- Justificatif de versement des indemnités de stage ou de Service Civique des 3 derniers mois',
  'document.tag.GUARANTEE_CERTIFICATE.details': 'Certificat de garantie de l\'organisme de cautionnement',
  'document.tag.EMPLOYER_PROOF.details': 'Transmettez tous les documents que vous pouvez parmi :<br/>'
    + '- Attestation de l\'employeur indiquant se porter garant<br/>'
    + '- Extrait K bis de la société<br/>'
    + '- 2 derniers bilans comptables de la société<br/>'
    + '- Pièce d\'identité du représentant légal de la société',
  'document.tag.GUARANTEE_COMMITMENT.details': 'Engagement de cautionnement de la banque',
  'document.docByDoc': 'Document',
  'document.completeFile': 'Dossier',
  'confirmation.documentDelete.title': 'Supprimer un document',
  'confirmation.documentDelete.message': 'Vous êtes sur le point de supprimer un document. Cette suppression est irréversible.',
  'confirmation.documentGroupDelete.title': 'Supprimer les documents',
  'confirmation.documentGroupDelete.message': 'Vous êtes sur le point de supprimer tous les documents de ce dossier. Cette suppression est irréversible.',
  'pageSelector.uploadDocument': 'Je préfère partager un fichier PDF unique contenant tous les documents',
  'pageSelector.displayDocument': 'Vos documents',
  'moveIn.ASAP': 'Dès que possible',
  'moveIn.NEXT_MONTH': 'D\'ici un mois',
  'moveIn.BEFORE_3_MONTH': 'Dans 3 mois',
  'moveIn.LATER': 'Pas avant 3 mois',
  'personalSituations.MARRIED': 'Marié(e)',
  'personalSituations.SINGLE': 'Célibataire',
  'personalSituations.WIDOWER': 'Veuf/veuve',
  'personalSituations.OTHER': 'Autre',
  'professionalSituation.EMPLOYED': 'Salarié',
  'professionalSituation.RETIRED': 'Retraité',
  'professionalSituation.STUDENT': 'Etudiant / Stagiaire',
  'professionalSituation.FREELANCER': 'Indépendant / Profession libérale',
  'professionalSituation.OFFICIAL': 'Fonctionnaire',
  'professionalSituation.JOB_SEEKER': 'Demandeur d\'emploi',
  'contractType.CDD': 'CDD',
  'contractType.CDI': 'CDI',
  'contractType.Alternant': 'Alternance',
  'contractType.Interimaire': 'Intérim',
  'contractType.Intermittent': 'CDI Intermittent',
  'rentingSituation.SOLO': 'Seul',
  'rentingSituation.COUPLE': 'En couple',
  'rentingSituation.FLATSHARING': 'En colocation',
  'garantType.INSURANCE': 'Assurance locative',
  'garantType.BANK': 'Banque',
  'garantType.ENTERPRISE': 'Entreprise',
  'garantType.PERSON': 'Personne physique',
  'candidature.notSelected': 'Veuillez sélectionner une candidature.',
  'candidature.notApplied': 'Vous n\'avez pas de candidature en cours !',
  'candidate.empty': 'Vous n\'avez pas de candidature en cours. Contactez les biens qui vous intéressent ou postulez directement depuis l\'application aux annonces de nos agences partenaires',
  'title.contact': 'Contacts',
  'document.selectAContact': 'Sélectionnez un contact pour compléter son dossier.',
  'candidate.continue': 'Poursuivre l\'ajout de documents sur ma candidature',
  'formButton.skip': 'Passer',
  'formButton.continue': 'Continuer',
  'candidate.finish': 'Revenir sur ma candidature pour la transmettre à l\'agent',
  'case.congrat.continue': 'Continuer sans message',
  'document.missing.help': 'Aidez l\'agent à comprendre pourquoi vous n\'avez pas ce document',
  'candidate.case.congrat': 'Bravo ! Vous avez déposé tous les documents de votre candidature',
  'document.deleteAll': 'Supprimer tous les documents',
  'form.firstname.question': 'Prénom ?',
  'form.firstname.label': 'Prénom',
  'form.lastname.question': 'Nom de famille ?',
  'form.lastname.label': 'Nom de famille',
  'form.email.question': 'Email ?',
  'form.email.label': 'Email',
  'form.phoneNumber.question': 'Numéro de telephone ?',
  'form.phoneNumber.label': 'Numéro de telephone',
  'form.situation.question': 'Situation ?',
  'form.situation.label': 'Situation',
  'form.leaseType.question': 'Type de contrat ?',
  'form.leaseType.label': 'Type de contrat',
  'form.trialPeriode.question': 'Période d\'essai validée ?',
  'form.trialPeriode.label': 'Réponse',
  'form.professionalMoveOut.question': 'Vous déménagez pour des raisons professionnelles (nouvel emploi, contrat d\'alternance ou mutation) ?',
  'form.professionalMoveOut.label': 'Réponse',
  'form.moreThan10Employe.question': 'Vous travaillez dans une entreprise privée de plus de 10 salariés ?',
  'form.moreThan10Employe.label': 'Réponse',
  'form.longDistance.question': 'Vous déménagez sur une distance de plus de 70 km OU à plus de 1h15 de temps de transport ?',
  'form.longDistance.label': 'Réponse',
  'form.profession.question': 'Profession ?',
  'form.profession.label': 'Profession',
  'form.enterprise.question': 'Nom de l\'employeur / entreprise ?',
  'form.enterprise.label': 'Nom',
  'form.monthlyIncome.question': 'Revenu net mensuel',
  'form.monthlyIncome.label': 'Revenu',
  'form.occupantRelationship.question': 'Vous louez ...',
  'form.occupantRelationship.label': 'Situation',
  'form.propertyId.question': 'Sélectionnez le bien qui vous intéresse',
  'form.propertyId.label': 'Annonce',
  'form.propertyId.notFound': 'Je ne trouve pas mon annonce',
  'form.propertyUrl.question': 'Ajoutez le lien de l\'annonce concernée',
  'form.propertyUrl.label': 'Url',
  'form.propertyUrl.invalideUrl': 'Votre url n\'appartient pas à un domaine present dans notre base de site immobilier. Pour des raisons de sécurité, nous ne pouvons pas l\'accepter. Si vous pensez qu\'il s\'agit d\'une erreur, vous pouvez nous l\'envoyer par le chat.',
  'form.message.question': 'Avez-vous des questions sur ce logement ?',
  'form.message.label': 'Autres infos ou questions',
  'form.moveInWanted.question': 'Quand souhaitez-vous emménager ?',
  'form.moveInWanted.label': 'Emménagement',
  'candidature.form.moveInWanted.label': 'Date idéale d\'emménagement souhaitée',
  'candidature.form.message.label': 'Souhaitez-vous écrire un message pour renforcer votre candidature ?',
  'form.contactAdd.question': 'Ajouter un autre colocataire ?',
  'form.hasGarants.question': 'Avez-vous un ou des garants ?',
  'form.hasGarants.label': 'Réponse',
  'form.garantAdd.question': 'Ajouter un autre garant ?',
  'form.save.question': 'Avez-vous d\'autres informations à partager sur votre situation ou bien des questions à poser sur ce logement ?',
  'form.type.question': 'Qui est votre garant ?',
  'form.type.label': 'Réponse',
  'form.name.question': 'Nom de l\'organisme ?',
  'form.nameEnterprise.question': 'Nom de l\'entreprise ?',
  'form.name.label': 'Nom',
  'form.amount.question': 'Montant maximum couvert ?',
  'form.amount.label': 'Montant',
  'form.ending.thanks': 'Merci d\'avoir renseigné vos informations.',
  'form.ending.findMore': 'Trouvez d\'autres biens similaires et obtenez des visites !',
  'form.ending.message': 'Nous reviendrons vers vous au plus vite pour organiser une visite ou répondre à vos interrogations si besoin. A bientôt.',
  'form.ending.team': 'L’équipe de %{team}',
  'form.chooseMultiple': 'Vous pouvez choisir plusieurs options.',
  'document.addDocumentMode': 'Mode d\'ajout',
  'form.field.rooms': 'pièces',
  'property.seePhotos': 'Voir les photos',
  'property.image.noImage': 'Pas d\'images',
  'property.description.description': 'Description',
  'property.description.hide': 'réduire',
  'property.description.showMore': 'Voir plus',
  'candidate.invitedToVisit': 'Vous avez été invité à une visite.',
  'candidate.chooseSlot': 'Sélectionner mon créneau',
  'candidate.notInterested': 'Je ne suis plus intéressé',
  'pageSelector.futurVisits': 'Visites à venir',
  'pageSelector.pastVisits': 'Visites passées',
  'pageSelector.isCanceled': 'Visite annulée',
  'pageSelector.proposed': 'Visite proposée',
  'visit.tag.isCanceled': 'Visite annulée',
  'visit.tag.proposed': 'Visite proposée',
  'visit.tag.pastVisits': 'Visite effectuée',
  'visit.tag.futurVisits': 'Visite prévue 🗓️',
  'candidature.tag.rejected': 'Abandon ❌',
  'candidature.upcomingVisit': 'Vous participez à la visite. ',
  'candidature.upcomingVisitDetails': 'Plus de détails ici.',
  'candidature.pastVisit': 'Vous avez visité ce bien. ',
  'property.reference': 'Ref',
  'formButton.notSure': 'Je ne sais pas',
  'candidature.caseAlreadyLinked': 'Le dossier est déjà présent sur cette candidature',
  'candidature.caseNotLinked': 'Le dossier est absent de cette candidature',
  'candidature.contactInfoMissing': 'Veuillez remplir les infos du contact avant de l\'ajouter à la candidature',
  'candidature.linkCase': 'Rattacher',
  'candidature.unlinkCase': 'Retirer',
  'applications.candidatureTab.intro1': 'Gérez ici l’association de ce dossier avec vos candidatures',
  'applications.candidatureTab.intro2': 'Le dossier doit être rattaché à au moins 1 candidature pour pouvoir y avoir accès.',
  'applications.candidatureTab.warningLastCandidature': 'Attention, si vous retirez le dossier de cette dernière candidature, toutes les informations de ce dossier seront définitivement supprimées.',
  'applications.candidatureTab.includeInCandidature': 'Inclure dans cette candidature ?',
  'applications.candidatureTab.asOccupant': 'En tant que locataire',
  'applications.candidatureTab.asGarant': 'En tant que garant',
  'form.fiscalRevenueN2': 'Le bien sélectionné est soumis au dispositif Pinel. Dans ce cadre, pour être éligible, le revenu fiscal de référence de votre foyer pour l\'année N-2 (en fonction de la composition du foyer) ne doit pas dépasser les plafonds réglementaires.<br/>'
    + '<br/>'
    + '<br/> Quel était le revenu fiscal de référence de votre foyer pour l\'année %{year} ?',
  'form.fiscalRevenueN2.label': 'Revenu fiscal de référence pour l\'année %{year}',
  'form.foyerCompositionN2': 'Quelle était la composition de votre foyer pour l\'année %{year} ?',
  'form.foyerCompositionN2.label': 'Composition de votre foyer pour l\'année %{year}',
  'pinel.composition': 'Composition du foyer',
  'pinel.aBis': 'A bis',
  'pinel.resteZoneA': 'Reste zone A',
  'pinel.b1': 'B1',
  'pinel.b2c': 'B2 et C',
  'pinel.SOLO': 'Personne seule',
  'pinel.COUPLE': 'Couple',
  'pinel.NCHILD': 'Personne seule ou couple ayant plus de 3 personnes à charge',
  'pinel.UNKNOWN': 'Composition du foyer inconnue',
  'pinel.from5thPerson': 'Majoration par personne à charge à partir de la cinquième',
  'field.error.nbChildren': 'Doit être compris entre 4 et 15',
  'form.nbChildren.label': 'Nombre de personnes à charge',

};

export type PossibleTraduction = keyof typeof fr;

const frTranslation: Traduction = {
  language: 'fr',
  messages: fr,
};

export default frTranslation;
