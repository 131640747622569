import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useAuth } from 'reactfire';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/compat/auth';
import sessionService from 'services/sessionService';
import SpinButton from 'theme/SpinButton';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LogInFormType } from 'types/forms/LogInFormType';
import { authentificationApi } from 'network/api/AuthentificationApi';
import { NotificationService } from 'lib/notification';
import { Link, useLocation } from 'react-router-dom';
import { EXPIRED, SIGN_IN } from 'routes/Routes';
import logo from 'assets/images/SwtBg.png';

export default function SignInForm() {
  const auth = useAuth();
  const location = useLocation();
  const [mailError, setMailError] = useState('');
  const [tokenError, setTokenError] = useState('');
  const [ssoConnection, setSsoConnection] = useState(false);

  const [pwdError, setPwdError] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const expired = queryParams.get(EXPIRED);
    if (expired) {
      setTokenError(Messages.t('error.expiredToken'));
    }
  }, []);

  const uiCallback = useCallback((ui) => {
    ui.disableAutoSignIn();
  }, []);

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult) => {
        sessionService.getSessionFromFirebase(authResult.user.accessToken).catch(() => {
          setMailError(Messages.t('error.invalidMail'));
        });
        return false;
      },
    },
  };
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LogInFormType>();

  const onSubmit: SubmitHandler<LogInFormType> = (formData: LogInFormType) => {
    setSubmitting(true);
    if (ssoConnection) {
      authentificationApi.recoverToken({ fromClient: true, mail: formData.mail })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.mailSend'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      sessionService.logIn(formData).catch(() => setPwdError(Messages.t('error.passwordInvalid'))).finally(() => {
        setSubmitting(false);
      });
    }
  };

  return (
    <div className="login-page">
      <img className="logo" src={logo} alt="logo" />
      <h1>SETTLESWEET</h1>
      <div className="login-card">
        <h2>{Messages.t('button.login')}</h2>
        <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mail-error">{tokenError}</div>
          <Controller
            name="mail"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.email')}
              />
            )}
          />
          {
            !ssoConnection && (
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    type="password"
                    control={controller}
                    label={Messages.t('field.password')}
                  />
                )}
              />
            )
          }
          <div className="mail-error">{pwdError}</div>
          <SpinButton
            editing
            className="connection-button"
            spin={submitting}
            title={
              ssoConnection
                ? Messages.t('formButton.sendConnectionMail')
                : Messages.t('formButton.confirm')
            }
          />
        </form>
        <button
          className="switch-button"
          type="button"
          disabled={submitting}
          onClick={() => setSsoConnection((prevState) => !prevState)}
        >
          {
            ssoConnection
              ? Messages.t('formButton.passwordConnection')
              : Messages.t('formButton.sendConnectionMail')
          }
        </button>
        <div>
          {Messages.t('login.noAccount')}
          <Link
            className="sign-in-button"
            to={SIGN_IN}
            onClick={() => setSsoConnection((prevState) => !prevState)}
          >
            {Messages.t('login.signIn')}
          </Link>
        </div>
        <h5>{Messages.t('generics.or')}</h5>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={auth}
          uiCallback={uiCallback}
        />
        <div className="mail-error">{mailError}</div>
      </div>
    </div>
  );
}
