import React from 'react';
import {
  Redirect,
  useParams,
} from 'react-router-dom';
import { PROPERTIES_DETAILS, PROPERTY_ID } from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';

type Param = {
  propertyId: string,
};

export default function PublicSearchRedirect() {
  const { propertyId } = useParams<Param>();
  return (
    <Redirect
      to={Routes.withPath(PROPERTIES_DETAILS, [{ label: PROPERTY_ID, value: propertyId }])}
    />
  );
}
