import React, { useEffect } from 'react';
import logo from 'assets/images/SwtBg.png';
import Messages from 'services/i18n/Messages';
import { Link, useHistory } from 'react-router-dom';
import { LOG_IN, SEARCH_CREATE_LANDING, SEARCHES } from 'routes/Routes';
import searchService from 'services/SearchService';

export default function ClassicLanding() {
  const history = useHistory();
  useEffect(() => {
    if (searchService.getSearch()) {
      history.push(SEARCHES);
    }
  }, []);
  return (
    <div>
      <div className="login-page">
        <img className="logo" src={logo} alt="logo" />
        <h1>SETTLESWEET</h1>
        <div className="login-card landing-card">
          <div className="landing-message">
            {Messages.t('landing.message')}
          </div>
          <div>
            <Link className="link-as-button" to={SEARCH_CREATE_LANDING}>{Messages.t('landing.startSearch')}</Link>
            <Link className="link-as-button secondary" to={LOG_IN}>{Messages.t('landing.login')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
