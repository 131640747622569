import React, { useEffect } from 'react';

import {
  Route,
  Switch,
} from 'react-router-dom';
import {
  PARAMETERS,
  PROPERTIES_DETAILS,
  SEARCHES,
} from 'routes/Routes';
import PublicSearch from 'pages/public-app/search/PublicSearch';
import signInService from 'services/SignInService';
import NavBar from 'pages/client-app/layout/NavBar';
import PropertyDetailsPage from 'pages/properties/PropertyDetailsPage';
import PublicSettings from 'pages/public-app/settings/PublicSettings';
import { detectMobile } from 'services/utils';

export default function PublicSearchRouter() {
  useEffect(() => {
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'kyjnxdm8',
      created_at: Date.now(),
      custom_launcher_selector: '#intercom_help_link',
      hide_default_launcher: detectMobile(),
    });
  }, []);
  return (
    <div className="client-root">
      <NavBar alternativeAction={() => signInService.setShowSignInModal(true, '')} />
      <div className="page-content">
        <Switch>
          <Route path={[SEARCHES]} component={PublicSearch} />
          <Route path={PROPERTIES_DETAILS} component={PropertyDetailsPage} />
          <Route exact path={PARAMETERS} component={PublicSettings} />
        </Switch>
      </div>
    </div>
  );
}
