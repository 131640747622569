import {
  ContactForm,
  FormField,
  moveInEnum,
  rentingSituationTypeEnum,
} from 'types/forms/CandidateForm';
import Messages from 'services/i18n/Messages';
import { Dispatch, SetStateAction } from 'react';
import { FieldArrayMethodProps } from 'react-hook-form';
import { propertyApi } from 'network/api/ProprertyApi';
import { foyerCompositionEnum } from 'types/Pinel';

export const getFormFields = (
  appendContact: (
    value: (Partial<ContactForm> | Partial<ContactForm>[]),
    options?: (FieldArrayMethodProps | undefined),
  ) => void,
  scrollTo: (index: number, skipPage?: boolean) => void,
  setContactNumber: Dispatch<SetStateAction<number>>,
  setGarantNumber: Dispatch<SetStateAction<number>>,
  save: () => void,
  pinelEnabledOnProperty?: boolean,
): FormField[] => [
  {
    pageId: 'start',
    questionlabel: '',
    fieldLabel: '',
    type: 'start',
  },
  {
    pageId: 'propertyId',
    questionlabel: Messages.t('form.propertyId.question'),
    fieldLabel: Messages.t('form.propertyId.label'),
    rules: { required: true },
    type: 'properties',
  },
  {
    pageId: 'propertyUrl',
    questionlabel: Messages.t('form.propertyUrl.question'),
    fieldLabel: Messages.t('form.propertyUrl.label'),
    rules: {
      required: true,
      validate: async (url) => {
        try {
          const isValid = await propertyApi.getUrlIsValide(url);
          if (isValid.valide) {
            return undefined;
          }
          // eslint-disable-next-line no-empty
        } finally {
        }
        return Messages.t('form.propertyUrl.invalideUrl');
      },
    },
    skipButton: Messages.t('formButton.skip'),
    condition: (formfield) => !formfield.propertyId,
    type: 'textField',
  },
  {
    pageId: 'moveInWanted',
    questionlabel: Messages.t('form.moveInWanted.question'),
    fieldLabel: Messages.t('form.moveInWanted.label'),
    rules: { required: true },
    type: 'select',
    selectOption: Object.values(moveInEnum).map((key) => ({ key, label: Messages.t(`moveIn.${key}`) })),
  },
  {
    pageId: 'contacts',
    questionlabel: '',
    fieldLabel: '',
    type: 'generator',
  },
  {
    pageId: 'contactAdd',
    questionlabel: Messages.t('form.contactAdd.question'),
    fieldLabel: '',
    actionButton: Messages.t('generics.YES'),
    skipButton: Messages.t('generics.NO'),
    type: 'action',
    condition: (formfield) => formfield.occupantRelationship
      === rentingSituationTypeEnum.FLATSHARING,
    action: (index) => {
      appendContact({});
      scrollTo(index - 1, true);
      setContactNumber((prevState) => prevState + 1);
    },
  },
  {
    pageId: 'hasGarants',
    questionlabel: Messages.t('form.hasGarants.question'),
    fieldLabel: Messages.t('form.hasGarants.label'),
    rules: { required: true },
    onSelectChange: (value) => {
      if (value === 'true') {
        setGarantNumber(1);
      } else {
        setGarantNumber(0);
      }
    },
    type: 'select',
    selectOption: [
      { key: 'true', label: Messages.t('generics.YES') },
      { key: 'false', label: Messages.t('generics.NO') }],
  },
  {
    pageId: 'garants',
    questionlabel: '',
    fieldLabel: '',
    type: 'generator',
  },
  {
    pageId: 'garantAdd',
    questionlabel: Messages.t('form.garantAdd.question'),
    fieldLabel: '',
    actionButton: Messages.t('generics.YES'),
    skipButton: Messages.t('generics.NO'),
    type: 'action',
    condition: (formField) => formField.hasGarants === 'true',
    action: (index) => {
      scrollTo(index - 1, true);
      setGarantNumber((prevState) => prevState + 1);
    },
  },
  {
    pageId: 'fiscalIncomesN2',
    fieldType: 'number',
    questionlabel: Messages.t('form.fiscalRevenueN2', { year: new Date().getFullYear() - 2 }),
    fieldLabel: Messages.t('form.fiscalRevenueN2.label', { year: new Date().getFullYear() - 2 }),
    rules: { required: true },
    condition: () => pinelEnabledOnProperty,
    type: 'textField',
  },
  {
    pageId: 'foyerCompositionN2',
    questionlabel: Messages.t('form.foyerCompositionN2', { year: new Date().getFullYear() - 2 }),
    fieldLabel: Messages.t('form.foyerCompositionN2.label', { year: new Date().getFullYear() - 2 }),
    rules: { required: true },
    type: 'select',
    condition: () => pinelEnabledOnProperty,
    selectOption: [...Object.values(foyerCompositionEnum).slice(0, 3).map((key) => ({
      key,
      label: Messages.t(`pinel.${key}`),
    }))],
  },
  {
    pageId: 'message',
    questionlabel: Messages.t('form.save.question'),
    rules: { required: true },
    fieldLabel: Messages.t('form.message.label'),
    type: 'end',
    fieldType: 'textarea',
    action: () => {
      save();
    },
    actionButton: Messages.t('formButton.send'),
  },
];
