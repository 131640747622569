import React from 'react';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import { Link } from 'react-router-dom';
import { LOG_IN } from 'routes/Routes';
import { FaceOutlined } from '@material-ui/icons';
import { detectMobile } from 'services/utils';

export default function PublicSettings() {
  const isMobile = detectMobile();
  return (
    <div className="page-body page-base settings-page">
      <h2>{Messages.t('parameters.title')}</h2>
      <div>
        <div className="login-button-container">
          <Link className="link-as-button secondary" to={LOG_IN}><FaceOutlined /> {Messages.t('landing.login')}</Link>
        </div>
        {
          isMobile && (
            <Button
              color="secondary"
              id="intercom_help_link"
            >
              {Messages.t('parameters.help')}
            </Button>
          )
        }
      </div>
    </div>
  );
}
