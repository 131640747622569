import { useMutation } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import { CandidatureCreation } from 'types/forms/CandidatureCreation';

export function useAdminBackend() {
  const SaveCandidature = useMutation<void, any, {
    data: CandidatureCreation,
    agencyId: string,
  }>(
    async ({ data, agencyId }) => {
      const response = await backend.post(`/admin/agencies/${agencyId}/candidatures`, data);
      return response;
    },
  );

  return {
    saveCandidature: SaveCandidature,
  };
}
