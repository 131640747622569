import React from 'react';

import SearchResults from 'pages/common/search/SearchResults';
import { useObservable } from 'micro-observables';
import searchService from 'services/SearchService';
import house from 'assets/images/house.svg';
import Messages from 'services/i18n/Messages';
import { Link } from 'react-router-dom';
import { SEARCH_CREATE_LANDING } from 'routes/Routes';

export default function PublicSearch() {
  const search = useObservable(searchService.getSearchObservable());
  return (
    search ? (
      <SearchResults search={search} />
    ) : (
      <div className="no-result-page">
        <div className="no-result-content">
          <img alt="logo" src={house} />
          <div>
            {Messages.t('search.noAlerts')}
            <Link className="action-link" to={SEARCH_CREATE_LANDING}>{Messages.t('formButton.clickHere')}</Link>
            {Messages.t('search.toCreateOne')}
          </div>
        </div>
      </div>
    )
  );
}
