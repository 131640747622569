import FetchAdapter from 'network/FetchAdapter';
import settings from 'services/settings';
import sessionManager from 'services/sessionManager';

class BackendFetchAdapter {
  private fetchAdapter;

  private backendEndpoint;

  private getToken;

  constructor(getToken: () => string | undefined) {
    this.fetchAdapter = new FetchAdapter();
    this.backendEndpoint = settings.backendEndpoint;
    this.getToken = getToken;
  }

  async fetch(path: string, funcParams?: any) {
    const url = `${this.backendEndpoint}${path}`;
    const token = this.getToken();
    let params = funcParams || {};

    if (token !== null) {
      params = {
        ...params,
        headers: {
          ...(params.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return this.fetchAdapter.fetch(url, params);
  }

  async fetchJson(path: string, params?: any) {
    const response = await this.fetch(path, params);
    if (response.headers.get('Content-Type') === 'application/json') {
      return response.json();
    }
    return response;
  }

  postFormData(path: string, formData: any, params = {}) {
    return this.fetchJson(path, {
      method: 'POST',
      body: formData,
      ...params,
    });
  }

  post(path: string, jsonBody?: any) {
    return this.fetchJson(path, buildParamsJsonBody('POST', jsonBody));
  }

  patch(path: string, jsonBody?: any) {
    return this.fetchJson(path, buildParamsJsonBody('PATCH', jsonBody));
  }

  put(path: string, jsonBody?: any) {
    return this.fetchJson(path, buildParamsJsonBody('PUT', jsonBody));
  }

  delete(path: string, jsonBody?: any) {
    return this.fetch(path, buildParamsJsonBody('DELETE', jsonBody));
  }
}

const backend = new BackendFetchAdapter(() => sessionManager.getCurrentToken());
export default backend;

function buildParamsJsonBody(method: any, jsonBody: any) {
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(jsonBody || {}),
  };
}
